import { Helmet, HelmetProvider } from 'react-helmet-async';

const getMetaTags = () => {
    const { hostname } = window.location;
    if (hostname.includes("drawadickontheheraldsun.com") || hostname.includes("au.ngrok.io")) {
        return {
            description: "Showcase your 'artistic' side and add another dick to the front page of today's Herald Sun.",
            title: "Draw a dick on the Herald Sun",
            domain: "https://drawadickontheheraldsun.com",
            banner: "https://drawadickontheheraldsun.com/banner-d.jpg",
            favicon: "https://drawadickontheheraldsun.com/ico/hs-favicon.ico",
            apple: "https://drawadickontheheraldsun.com/ico/hs-apple-touch-icon.png",
            favicon32: "https://drawadickontheheraldsun.com/ico/hs-favicon-32x132.png",
            favicon16: "https://drawadickontheheraldsun.com/ico/hs-favicon-16x16.png",
            manifest: "https://drawadickontheheraldsun.com/ico/hs-manifest.json",
        }
    }
    return {
        description: "Get creative with the news! Draw, scribble, graffiti and deface real newspaper articles from your favorite (and least favorite) newspapers with News Doodler. Join our community of doodlers and make your mark on the news. Add some humor, wit and satire to your daily with News Doodler.",
        title: "News Doodler",
        domain: "https://newsdoodler.com",
        banner: "https://newsdoodler.com/banner.jpg",
        favicon: "https://newsdoodler.com/ico/favicon.ico",
        apple: "https://newsdoodler.com/ico/apple-touch-icon.png",
        favicon32: "https://newsdoodler.com/ico/favicon-32x132.png",
        favicon16: "https://newsdoodler.com/ico/favicon-16x16.png",
        manifest: "https://newsdoodler.com/ico/manifest.json",
    }
}

const Header = () => {

    const { description, title, domain, banner, favicon, apple, favicon16, favicon32, manifest } = getMetaTags()
    return (<HelmetProvider><Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={domain} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={banner} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={domain} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={banner} />
        <link rel="icon" href={favicon} />
        <link rel="apple-touch-icon" href={apple} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
        <link rel="manifest" href={manifest} />
    </Helmet></HelmetProvider>)
}

export default Header;