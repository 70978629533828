import React from 'react';
import Select from 'react-select';
import papersJSON from "../data/papers.json"

const papers = papersJSON.map(m => ({ label: m.label, value: m.name }));

const PaperSelect = ({ updateImage, paperParam }) => {
    const selectedPaper = papers.find(f => f.value === paperParam);
    const defaultPaper = selectedPaper || papers[0];
    return <Select onChange={updateImage} defaultValue={defaultPaper} options={papers} />

}

export default PaperSelect;