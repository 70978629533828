import './App.css';
import ArtBoard from "./components/artBoard"
import useWindowDimensions from "./calculateSize"
import styled from 'styled-components';
import Footer from './components/footer';
import { useEffect } from "react"
import Header from './components/header';



const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  background: #e5e5e5;
  ${({ height }) => `height:${height}px;`}
  display: grid; 
  grid-template-columns: 1fr; 
  grid-template-rows: 1fr 50px; 
  gap: 0px 0px; 
  grid-template-areas: 
    "artboard"
    "footer";
  background-color: #fff;
`
function App() {

  useEffect(() => {    // Update the document title using the browser API
  });
  const { height, width } = useWindowDimensions();
  return (<>
    <Header />
    <Container height={height}>
      <ArtBoard width={width} height={height}></ArtBoard>
      <Footer />
    </Container></>
  );
}

export default App;
