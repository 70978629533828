import styled from 'styled-components';
import React from 'react';
import Palette from "./palette"
import Canvas from "./canvas"
import PaperSelect from './paperSelect';
import { saveAs } from 'file-saver';
import Button from './Button';
import Modal from "./Modal"
import papers from "../data/papers.json"

const Container = styled.div`
    display: grid; 
    overflow: hidden;
    grid-template-columns: 215px 1fr; 
    grid-template-rows: 1fr; 
    gap: 0px; 
    grid-template-areas: "sidePanel canvas";
    grid-area: artboard;
    // border: 1px #f00 solid;

    @media (max-width: 768px) {
        grid-template-columns: 1fr; 
        grid-template-rows:  max-content max-content 1fr max-content; 
        gap: 0px 0px; 
        grid-template-areas: 
            "palette"
            "paperSelect"
            "canvas"
            "controls";
    }

`

const Logo = styled.div`
    font-family: Schoolbell, sans-serif;
    font-size: 2em;
    padding: 10px;
    text-align:center;
`

const CanvasContainer = styled.div`
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    grid-area: canvas;
    overflow: hidden;
    display: flex;
    // border: 1px #000 solid;
    background: #fafafa;
    align-items: center;
    justify-content: center;`

const CanvasEffect = styled.div`
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    // border: 2px #aaa solid;
`

const Controls = styled.div`
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    grid-area: controls;
    align-items: center;
    justify-content: space-around;
`

const PaperSelectContainer = styled.div`
    width: 200px;
    grid-area: paperSelect;
    align-items: center;
    margin: 0 auto;
`

const SidePanel = styled.div`
    display: grid;
    grid-area: sidePanel;
    overflow: hidden;
    grid-template-rows: 60px 400px max-content 1fr; 
    grid-template-columns: 1fr;
    gap: 0px; 
    grid-template-areas: 
        "logo" 
        "palette" 
        "paperSelect" 
        "controls";
`

export default class ArtBoard extends React.Component {

    constructor(props) {
        super(props);
        this.saveableCanvas = React.createRef();
        this.popUpModal = React.createRef();
        this.resetTweet = this.resetTweet.bind(this)
    }

    state = {
        brushColor: '#070707',
        brushRadius: 1,
        paper: this.getDefualtPaper(),
        modalIsOpen: false,
        isTweetLoaded: false
    }

    setBrushColour = (brushColor, e) => {
        this.setState({ brushColor });
        if (e.target.hasAttribute("selected")) {
            const brushRadius = this.state.brushRadius;
            if (brushRadius >= 4) {
                this.setState({ brushRadius: 0 });
            }
            else { this.setState({ brushRadius: brushRadius + 1 }); }
        }
        if (this.state.previousSwatch) {
            this.state.previousSwatch.target.removeAttribute("selected")
        }
        this.setState({ previousSwatch: e })
        e.target.setAttribute("selected", true)
    }

    updateImage(imgSrc) {
        const url = new URL(window.location);
        window.history.pushState(null, '', `${url.origin}/${imgSrc.value}`);
        // this.setState({ isTweetDisabled: true })
        this.setState({ paper: imgSrc.value });
        this.erase()
    }

    getMaxCanvasSize() {
        const canvas = document.getElementById("canvas");
        if (canvas) {
            const width = canvas.offsetWidth;
            const height = canvas.offsetHeight;
            return { width, height };
        }
        return {}
    }
    componentDidMount() {
        this.forceUpdate();
    }
    getDefualtPaper() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const paperParam = urlParams.get('paper');

        const queryPath = window.location.pathname;
        const urlPath = queryPath.split("/")[1];

        const defualtPaper = paperParam || urlPath;
        const validatedPaper = papers.find(f => f.name === defualtPaper);

        return validatedPaper ? validatedPaper.name : "herald-sun";
    }

    undo() {
        this.saveableCanvas.current.undo();
    }

    erase() {
        this.saveableCanvas.current.erase();
    }

    save() {
        const blob = this.saveableCanvas.current.save();
        saveAs(blob, 'newsdoodler.png');
    }

    resetTweet() {
        console.log("RESETTING")
        this.setState({ isTweetLoaded: null, tweetURL: null, modalIsError: null })
    }

    tweet() {
        this.popUpModal.current.openModal();
        this.saveableCanvas.current.tweet(({ tweetURL, error }) => {

            if (!tweetURL) {
                this.setState({ modalIsError: true });
            } else {
                this.setState({ tweetURL })
            }
            this.setState({ isTweetLoaded: true })
        });
    }

    render() {
        const maxSize = this.getMaxCanvasSize();
        const { brushColor, brushRadius, paper, isTweetDisabled } = this.state;
        const { width } = this.props;
        const date = new Date(new Date().toLocaleString("en-US", { timeZone: "Australia/Sydney" }))
        const trimDate = date.toISOString().split('T')[0];
        // const imgSrc = `http://s3.amazonaws.com/hsd-img/${paper}.png?version=${trimDate}`;
        const imgSrc = `${process.env.PUBLIC_URL}/papers/${paper}.png?version=${trimDate}`;
        const logo = <Logo>News Doodler</Logo>
        const paperSelectElement = <PaperSelectContainer><PaperSelect paperParam={paper} updateImage={this.updateImage.bind(this)} /></PaperSelectContainer>
        const paletteElement = <Palette setBrushColour={this.setBrushColour} brushRadius={brushRadius} />
        const controlsElement = (<Controls>
            <Button text={"↶ Undo"} onClick={() => this.undo()} ></Button>
            <Button id={"clear"} text={"Clear"} onClick={() => this.erase()} >Erase</Button>
            {!isTweetDisabled && <Button text={"Tweet"} onClick={() => this.tweet()} >Tweet</Button>}
            <Button text={"Download"} onClick={() => this.save()} >Download</Button>
        </Controls>)
        const sidePanel = <SidePanel>
            {logo}
            {paletteElement}
            {paperSelectElement}
            {controlsElement}
        </SidePanel>
        return (<Container>
            <Modal
                ref={this.popUpModal}
                isLoaded={this.state.isTweetLoaded}
                tweetURL={this.state.tweetURL}
                modalIsError={this.state.modalIsError}
                resetTweet={this.resetTweet}
            />
            {width > 768 && sidePanel}
            {width <= 768 && paletteElement}
            {width <= 768 && paperSelectElement}
            {width <= 768 && controlsElement}
            <CanvasContainer id="canvas">
                <CanvasEffect>
                    <Canvas
                        ref={this.saveableCanvas}
                        imgSrc={imgSrc}
                        brushColor={brushColor}
                        maxWidth={maxSize.width || 100}
                        maxHeight={maxSize.height || 100}
                        brushRadius={brushRadius}
                    /></CanvasEffect>
            </CanvasContainer>

        </Container>)
    }

}