import React, { useImperativeHandle, forwardRef } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { ThreeDots } from 'react-loader-spinner'
import Button from "./Button"

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ModalContent = styled.div`
  text-align: center;
  min-width: 300px;
  margin: 10px;
`

const ModalText = styled.div`
  margin:10px;
  margin-bottom:20px;`

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const ModalEl = forwardRef((props, ref) => {
  const { isLoaded, tweetURL, modalIsError, resetTweet } = props;

  useImperativeHandle(ref, () => ({
    openModal() {
      setIsOpen(true);
    }
  }));

  // let subtitle;
  const [isOpen, setIsOpen] = React.useState(false);

  function sendTweet() {
    closeModal()
    window.open(tweetURL, '_blank')
  }

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
    resetTweet()
  }

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <ModalContent>
          {!isLoaded &&
            (<><div>Creating your work of art. Please wait.</div>
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true} /></>)}
          {isLoaded && (!modalIsError ?
            (<><ModalText>Doodle sucessfully created!<br />You're officially an artist.</ModalText><Button text={"Send Tweet"} onClick={() => sendTweet()}></Button></>)
            : (<><ModalText>Sorry, something went wrong with creating your Tweet.<br />You can still download your image and do it the old fashioned way!</ModalText><Button text={"Okay :("} onClick={() => closeModal()}></Button></>))
          }
        </ModalContent>
      </Modal>
    </div>
  );
})

export default ModalEl;