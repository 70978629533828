import styled from 'styled-components';
import React from 'react';

const selectedRadius = 45;

const Swatch = styled.div`
    border-radius:50px;
    width:37px;
    height:37px;
    margin: 5px;
    position: relative;
    ${({ colour }) => `background-color:${colour};`}
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    border: 5px solid #fff;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transition: 0.2s;

    &:hover{
        margin-bottom:7px;
        // width: 42px;
        // height: 42px;
        // border: 6px solid #fff;
    }

    &[selected]{
        ${({ brushRadius }) => {


        if (brushRadius >= 4) {
            return `border: ${(selectedRadius / 2) - 18}px solid #fff;`
        }
        if (brushRadius === 3) {
            return `border: ${(selectedRadius / 2) - 14}px solid #fff;`
        }
        if (brushRadius === 2) {
            return `border: ${(selectedRadius / 2) - 10}px solid #fff;`
        }
        if (brushRadius === 1) {
            return `border: ${(selectedRadius / 2) - 6}px solid #fff;`
        }
        if (brushRadius <= 0) {
            return `border: ${(selectedRadius / 2) - 2}px solid #fff;`
        }
    }}
        width: ${selectedRadius}px;
        height: ${selectedRadius}px;
    }
`;

const ColourPanel = styled.div`
    display: grid;
    place-items: center;
    grid-template-columns: repeat( auto-fill, 50px );
    grid-template-rows: repeat( auto-fill, 50px );
    gap: 10px;
    padding:10px;
    background: #fff;
    grid-area: palette;
    // border: 1px #0f0 solid;


    @media (max-width: 768px) {
        grid-gap: 10px;
        grid-auto-flow: column;
        grid-auto-columns: 50px;
        overflow-x: scroll;
        min-height:50px;
    }
`;




export default class Palette extends React.Component {

    constructor(props) {
        super(props);
        this.setBrushColour = this.props.setBrushColour;
    }

    render() {
        const colours = [
            { colour: "#070707" },
            { colour: "#181e25" },
            { colour: "#63676c" },
            { colour: "#FF5D1A" },
            { colour: "#E7E860" },
            { colour: "#00C891" },
            { colour: "#0097D1" },
            { colour: "#6FBEDC" },
            { colour: "#FC0049" },
            { colour: "#8D5524" },
            { colour: "#C68642" },
            { colour: "#E0AC69" },
            { colour: "#F1C27D" },
            { colour: "#FAFAFA" }];
        const swatches = colours.map(colour => (<Swatch brushRadius={this.props.brushRadius} key={colour.colour} colour={colour.colour} onClick={(e) => this.setBrushColour(colour.colour, e)}></Swatch>));
        return (<ColourPanel test="test">{swatches}</ColourPanel>)
    }
}