import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';


const FooterEl = styled.div`
  grid-area: footer;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 10px;
    font-size: 0.9em;
    text-align: center;
}`

const getMetaData = (hostname) => {
  if (hostname.includes("drawadickontheheraldsun") || hostname.includes("ngrok")) {
    return {
      url: "https://drawadickontheheraldsun.com",
      text: "I drew a dick on the Herald Sun!",
      hashtag: "newsdoodler",
      shortUrl: "drawadickontheheraldsun.com",
      kofiCopy: "Shout me a beer"
    }
  }
  return {
    url: "https://newsdoodler.com",
    text: "Doodle on today's news.",
    hashtag: "newsdoodler",
    shortUrl: "newsdoodler.com"
  }
}

const Kofi = styled.a`
border-radius: 8px;
background-color: #29abe0;
color: #fff;
padding: 0px 10px;
height: 20px;
text-decoration: none;
font-weight: bold;
font-size: 13px;
line-height: 20px;
`

export default function Footer() {
  const { hostname } = window.location;
  const { url, text, shortUrl, hashtag, kofiCopy } = getMetaData(hostname)
  const shareUrl = encodeURI(url);
  return (<FooterEl>
    <Kofi href='https://ko-fi.com/I2I3BUI7V' target='_blank' rel="noreferrer">
      <img style={{ height: "12px" }} alt="kofi" src={`${process.env.PUBLIC_URL}/kofi.webp`}></img>
      <span style={{ marginLeft: "5px" }}>{kofiCopy ? kofiCopy : "Buy me a coffee"}</span>
    </Kofi>
    <div className="fb-share-button"
      data-href={shareUrl}
      data-layout="button_count">
    </div>
    <a className="twitter-share-button"
      href={`${url}?text=${text}&url=${shortUrl}&hashtags=${hashtag}`}>
      Tweet</a>
    {!isMobile && <a href="mailto:hello@newsdoodler.com">Contact</a>}
    {!isMobile && <a href="https://twitter.com/news_doodler">Gallery</a>}

  </FooterEl>)
}