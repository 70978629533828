import styled from 'styled-components';
import { ThreeDots } from 'react-loader-spinner'

const Loader = styled.div`
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  position: absolute;
  transform: translate(-50%, -50%);`

const LoaderEl = () => {
    return <Loader>
        <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true} /></Loader>
}

export default LoaderEl;