import React from 'react';
import axios from 'axios'
import { isMobile } from 'react-device-detect';
import CanvasDraw from "react-canvas-draw";
import Loader from "./loader"

export default class Canvas extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.onImgLoad = this.onImgLoad.bind(this);
    }
    onImgLoad({ target: img }) {
        this.setState({
            imgDimensions: {
                height: img.offsetHeight,
                width: img.offsetWidth
            }
        });
        setTimeout(() => {
            this.mimicStroke();
        }, 100)
    }

    calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
        const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
        return { width: srcWidth * ratio, height: srcHeight * ratio };
    }

    undo() {
        this.saveableCanvas.undo();
    }

    erase() {
        this.saveableCanvas.eraseAll();
        this.setState({
            imgDimensions: null
        });
    }

    componentDidMount() {
        setTimeout(() => {
            this.mimicStroke();
        }, 500)
    }

    mimicStroke() {

        this.forceUpdate();
        if (!this.saveableCanvas) {
            return;
        }
        if (isMobile) {
            const canvases = document.querySelectorAll("canvas");
            canvases[canvases.length - 1].style.opacity = 0;
        }
        // For some reason, the first line gets erased when on mobile.
        // So we create a fake line to be erased.
        // At least this way, we can control what gets erased!)
        this.saveableCanvas.loadSaveData(JSON.stringify({ "lines": [{ "points": [{ "x": 13.847582834605117, "y": 14.376451728907874 }, { "x": 13.847582834605117, "y": 14.376451728907874 }, { "x": 13.847582834605117, "y": 14.376451728907874 }], "brushColor": "#fff", "brushRadius": 1 }], "width": 505.75333333333333, "height": 709 }), false)
    }

    tweet(callback) {
        const dataUri = this.createDataURL();
        axios.post("https://8aptxlmlhf.execute-api.us-east-1.amazonaws.com/image", { dataUri: dataUri.split(';base64,')[1] })
            .then(res => {
                if (res.data.error) {
                    console.log(res.data.error)
                }
                const body = JSON.parse(res.data.response);
                const urlChunks = body.text.split(" ");
                const url = urlChunks[urlChunks.length - 1]
                const tweetURL = `https://twitter.com/intent/tweet?text=I doodled today's news with newsdoodler.com ${encodeURI(url)}&hashtags=newsdoodler`;
                callback({ tweetURL })
            })
            .catch(err => callback({ error: err }))
    }

    createDataURL() {
        const canvases = document.querySelectorAll("canvas");
        const newCanvas = document.createElement("canvas");
        newCanvas.width = canvases[0].width;
        newCanvas.height = canvases[0].height;
        canvases.forEach(e => {
            const newCanvasCX = newCanvas.getContext('2d');
            newCanvasCX.drawImage(e, 0, 0);
        })
        return newCanvas.toDataURL("image/png");
    }

    save() {
        const dataURL = this.createDataURL();
        navigator.clipboard.writeText(dataURL.split(';base64,')[1])
        return dataURL;
    }

    getData() {
        if (this.saveableCanvas) {
            const saveData = this.saveableCanvas.getSaveData();
        }
    }

    resize = () => this.forceUpdate()

    render() {
        const { imgSrc, brushColor, maxWidth, maxHeight, brushRadius } = this.props;
        const { imgDimensions } = this.state
        if (!imgDimensions) {
            return <><Loader /><img style={{ opacity: 0 }} onLoad={this.onImgLoad} src={imgSrc} alt="Error downloading paper" /></>
        }

        const size = this.calculateAspectRatioFit(imgDimensions.width, imgDimensions.height, maxWidth, maxHeight);
        return (<CanvasDraw
            hideGrid
            ref={canvasDraw => (this.saveableCanvas = canvasDraw)}
            imgSrc={imgSrc}
            canvasHeight={size.height}
            canvasWidth={size.width}
            brushColor={brushColor}
            brushRadius={(brushRadius * 3) + 1}
            lazyRadius={2}
        />)
    }
}